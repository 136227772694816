<template>
  <section class="teaser  o-panel">
    <div class="teaser__inner  o-panel__inner">
      <template v-if="!sweepstakeSubmitted">
        <h1 class="teaser__headline  u-h1">
          Deine Gefühle. Deine Spotify-Playlist. Deine Gewinnchance.
        </h1>
        <p class="teaser__copy  u-h3">
          Entdecke mit Aspirin® den Klang deiner Gefühle und gewinne mit etwas
          Glück einen Spotify Gutschein!
        </p>
        <div class="o-row">
          <anchor-link
            to="emotionserkennung"
            class="c-button c-button--pill c-button--black"
          >
            Zur Emotionserkennung
          </anchor-link>
        </div>
      </template>
      <template v-else>
        <h1 class="teaser__headline  u-h1">
          Deine Gefühle. Deine Spotify-Playlist. Deine Gewinnchance.
        </h1>
        <p class="teaser__copy  u-h3">
          Du hast erfolgreich an unserem Gewinnspiel teilgenommen.<br />
          Vielen Dank und viel Glück!
        </p>
      </template>
      <img class="teaser__logo" :src="spotifyLogo" />
    </div>
  </section>
</template>

<script>
import SpotifyLogo from '@/assets/images/spotify-logo.png'
import AnchorLink from '@/components/AnchorLink'
import { mapState } from 'vuex'

export default {
  components: {
    AnchorLink
  },
  computed: {
    ...mapState(['sweepstakeSubmitted']),
    spotifyLogo() {
      return SpotifyLogo
    }
  }
}
</script>

<style lang="stylus">
@require '../assets/style/1-settings/colors'
@require '../assets/style/1-settings/layout'

.teaser
  display flex
  padding-top 7rem
  padding-bottom 3rem
  color #391C5C
  background linear-gradient(#AD9AFB, #EEE2FD)

  +above('l')
    background url('~@/assets/images/teaser-bg-person.png'), linear-gradient(#AD9AFB, #EEE2FD)
    background-position 95% 100%
    background-repeat no-repeat
    background-size auto 80%, auto

  +mobile()
    min-height 100vh
    min-height calc(var(--vh, 1vh) * 100)
    align-items center

  &__inner
    text-align center
    max-width 860px !important

  &__headline
    max-width 36rem
    margin-right auto
    margin-left auto

  &__copy
    max-width 30rem
    margin 0 auto
    margin-bottom 2rem

  &__logo
    display inline
    width 120px
    height 36px
    margin-top 2.5rem

  .c-button
    margin-top 1rem
</style>
